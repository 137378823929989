import React from 'react';

// import components
import ContactComponent from '../components/Contact';

const Contact = () => {
    return (
        <ContactComponent />
        // TODO: Make it to send an actual message to info@l7mp.io
    );
};

export default Contact;