import React from 'react';

// import components
import ServicesComponent from '../components/Services';

const Services = () => {
    return (
        <ServicesComponent />
    );
};

export default Services;