import React from 'react';

// import components
import BlogComponent from '../components/Blog';

const Blog = () => {
    return (
        <BlogComponent />
        // TODO: Add pagination
    );
};

export default Blog;